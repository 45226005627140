.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rbc-event-content {
  z-index: 9990;
}
/*.event-edit-popup {*/
/*  position: fixed;*/
/*  top: 10%;*/
/*  left: 50%;*/
/*  transform: translate(-50%, -50%);*/
/*  z-index: 9999;*/
/*}*/
.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}
.event-edit-popup {
  /* Set the position of the popup to fixed, so that it stays in the same place on the screen */
  position: fixed;
  /* Position the popup in the middle of the screen, both horizontally and vertically */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Set the z-index to a high value, to ensure that the popup is displayed on top of other elements */
  z-index: 9999;
  /* Set the background color of the popup to white */
  background-color: white;
  /* Set the width of the popup to 500px */
  width: 500px;
  /* Add some padding to the inside of the popup */
  padding: 20px;
  /* Add rounded corners to the popup */
  border-radius: 10px;
  /* Add a box-shadow to the popup to make it stand out from the background */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}
:root {
  --selected-status-color: #fff; /* default value */
}
.status-select option[selected] {
  background-color: var(--selected-status-color) !important;
  color: white;
}
.status-select option {
  background-color: inherit;
}
.status-select option[data-status="Done"] {
  background-color: #CEF5D2;
}
.status-select option[data-status="Todo"] {
  background-color: #FFD4DF;
}
.status-select option[data-status="In progress"] {
  background-color: #FFEAB6;
}
.status-select option[selected] {
  background-color: var(--selected-status-color) !important;
  color: white;
}
select.status-select option:checked {
  background-color: var(--selected-status-color);
}
select.status-select {
  background-color: var(--selected-status-color);
}
.rbc-event-bg-color {
  background-color: var(--selected-status-color) !important;
}

.rbc-event {
  /*background-color: var(--default-status-color) !important;*/
  color: var(--default-text-color);
  border: 1px solid gray !important;
}
.status-select {
  background-color: var(--selected-status-color) !important;
  color: var(--selected-status-color-text) !important;
}
.rbc-event, .rbc-day-slot .rbc-background-event{
  /*background-color: var(--default-status-color) !important;*/
  color: var(--default-text-color);
  border: 1px solid gray !important;
}
.rbc-event, .rbc-day-slot .rbc-background-event{
  background-color: #fff;
}